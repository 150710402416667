export const API_ENDPOINT = 
    process.env.REACT_APP_API_ENDPOINT ||
    "https://bmlonline-dev-us.uc.r.appspot.com" ;
    // "https://bmlonline-liv-us.uc.r.appspot.com" ;

export const API_UPLOAD_ENDPOINT = 
    process.env.REACT_APP_VIDEO_API_ENDPOINT ||
    "https://bmlovideo-dev-us.uc.r.appspot.com";
    //https://bmlovideo-liv-us.uc.r.appspot.com/;

export const API_METRICS_ENDPOINT =
    process.env.REACT_APP_METRICS_API_ENDPOINT ||
    "https://dev.api.analytics.bodymindlife.online/v1";
    //"https://api.analytics.bodymindlife.online/v1";    