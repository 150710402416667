import React from "react";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { Col, Image, Row, Button } from "antd";
import { audioTest } from "../../actions/actions";

const TestScreenButtons = (props) => {

    const { audioTestCompleted, networkTestCompleted } = props;

    const onNextClick = () => {
        if (!audioTestCompleted) {
            props.audioTest({
                audio: true,
                network: false
            });
        } else {
            props.audioTest({
                audio: true,
                network: true
            });
        }
    };

    const onBackClick = () => {
        if (audioTestCompleted && networkTestCompleted) {
            props.audioTest({
                audio: true,
                network: false
            });
        } else if (audioTestCompleted && !networkTestCompleted) {
            props.audioTest({
                audio: false,
                network: false
            });
        }
    }

    return (
        <div style={{ display: "flex" }}>
            {
                !audioTestCompleted ? (
                    <Link to="/otsHome" >
                        <div
                            className="footer-button"
                            style={{ width: "100%" }}
                        >
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="test-next-button"
                            >
                                Test Already Done
                            </Button>
                        </div>
                    </Link>
                ) : (
                    <div
                        className="footer-button"
                        style={{ width: "100%" }}
                    >
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="test-next-button"
                            onClick={onBackClick}
                        >
                            Back
                        </Button>
                    </div>
                )
            }
            {
                audioTestCompleted && networkTestCompleted ? (
                    <Link to="/otsHome" >

                        <div
                            className="footer-button"
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-end"
                            }}
                        >
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="test-next-button"
                            >
                                Done
                            </Button>
                        </div>
                    </Link>
                ) : (
                    <div
                        className="footer-button"
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end"
                        }}
                    >
                        <a>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="test-next-button"
                                onClick={onNextClick}
                            >
                                Next
                            </Button>
                        </a>
                    </div>

                )
            }
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        audioTest: (data) => dispatch(audioTest(data)),
    };
};

const mapStateToProps = (state) => {
    return {
        networkTestCompleted: state.networkTestCompleted,
        audioTestCompleted: state.audioTestCompleted,
    };
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(TestScreenButtons);
