import React, { useState, useLayoutEffect } from "react";
import { Tooltip } from 'antd';

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeigh]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

const HelpTooltip = (props) => {
    const { title, margin } = props
    const [width, height] = useWindowSize();
    const responsiveStyle = {
        marginTop: 6
    }
    const desktopStyle = {
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        marginTop: margin
    }
    return (
        <div style={ width < 350 ? responsiveStyle : desktopStyle}>
            <div className="referrals-content-container">
                <Tooltip overlayClassName={"referrals-content-tooltip"} getPopupContainer={triggerNode => triggerNode.parentNode} placement="bottom" title={<span className="referrals-content-tooltip-span">{title}</span>}>
                    <span className="referrals-content-tooltip-main" style={{ textAlign: 'center', fontSize: 14, color: '#fff' }}>?</span>
                </Tooltip>
            </div>
        </div>
    );
}

export default HelpTooltip;