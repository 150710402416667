import React, { useEffect, useRef } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router";
import { Layout, Col, Image, Row, message } from "antd";

import LoginForm from "../../components/Login/loginForm";
import SideImage from "../../assets/img/sideScreen.png";
import LoginHeader from "../../components/Header/loginHeader";
import { changeLoginState, postLoginData } from "../../actions/actions";

const Login = (props) => {
  const { loginData, loginStatusCode, tokenExpire } = props;
  let login = useRef(loginStatusCode);
  console.log("build 4");
  const { Footer } = Layout;

  useEffect(() => {
    if (login.current != loginStatusCode) {
      message.error({
        content: "Invalid Email or Password. Please enter valid credentials!",
      });
    }
    login.current = loginStatusCode;
  }, [loginStatusCode]);

  return (
    <>
      {!loginData || tokenExpire ? (
        <>
          <LoginHeader />
          <div className="bg-signup">
            <Row
              style={{
                height: "100%",
                alignItems: "center",
              }}
            >
              <Col className="gutter-row"
                xs={24}
                md={24}
                lg={12}
              >
                <div className="login-side-image">
                  <Image
                    className="side-signup-image"
                    src={SideImage}
                    preview={false}
                  />
                  <div className="login-side-image-heading">
                    <p className="login-side-image-heading-one">BMLOnline</p>
                    <p className="login-side-image-heading-two">
                      Empowering Educators
                    </p>
                  </div>
                </div>
              </Col>
              <Col className="gutter-row"
                xs={24}
                md={24}
                lg={12}
              >
                <LoginForm />
              </Col>
            </Row>
            <Footer
              style={{
                background: "none",
                padding: 0,
                position: "static",
                width: "100vw",
                height: 40,
                bottom: 0,
                overflow: "hidden",
              }}
              className="home-footer"
            >
              <Row style={{ justifyContent: "center" }}>
                <p style={{ marginBottom: 0, marginTop: 10 }}>
                  © Super44 Tech Holdings Pty Ltd, All Rights Reserved
                </p>
              </Row>
            </Footer>
          </div>
        </>
      ) : (
        <Redirect to="/main" />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    postLoginData: (data) => dispatch(postLoginData(data)),
    changeLoginState: (data) => dispatch(changeLoginState(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    pending: state.pending,
    isLogin: state.isLogin,
    loginData: state.loginData,
    tokenExpire: state.tokenExpire,
    loginSuccessful: state.loginSuccessful,
    loginStatusCode: state.loginStatusCode,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Login);
