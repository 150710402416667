import React from "react";
import { Divider } from "antd";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import AudioIcon from "../../assets/img/icons/audioSpeaker.svg";
import EnabledVideoIcon from "../../assets/img/icons/videoEnable.svg";
import DisabledVideoIcon from "../../assets/img/icons/videoDisable.svg";
import EnabledNetworkIcon from "../../assets/img/icons/networkEnable.svg";
import DisabledNetworkIcon from "../../assets/img/icons/networkDisable.svg";

const TestIconFlow = (props) => {

    const { audioTestCompleted, networkTestCompleted } = props;

    return (
        <div className="testing-flow">
            <div className="audio-block">
                <div className="audio-icon" >
                    <img className="test-icon-img" src={AudioIcon} />
                </div>
                <div className="title-testicons" style={{ color: "#383838" }}>
                    <span>Audio Test</span>
                </div>
            </div>

            <div className="divider">
                <Divider className="divider-line" style={{ backgroundColor: audioTestCompleted ? "#383838" : null }} />
            </div>

            <div>
                <img
                    className="test-icon-img"
                    src={audioTestCompleted ? EnabledNetworkIcon : DisabledNetworkIcon}
                />
                <div className="title-testicons" style={{ color: audioTestCompleted ? "#383838" : "#9B9B9B" }}>
                    <span>Network Test</span>
                </div>
            </div>

            <div className="divider">
                <Divider className="divider-line" style={{ backgroundColor: audioTestCompleted && networkTestCompleted ? "#383838" : null }} />
            </div>

            <div className="video-block">
                <img
                    className="test-icon-img"
                    src={audioTestCompleted && networkTestCompleted ? EnabledVideoIcon : DisabledVideoIcon}
                />
                <div className="title-testicons" style={{ color: audioTestCompleted && networkTestCompleted ? "#383838" : "#9B9B9B" }}>
                    <span>Video Test</span>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        audioTestCompleted: state.audioTestCompleted,
        networkTestCompleted: state.networkTestCompleted,
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, null)
)(TestIconFlow);