import React from "react";
import { withRouter } from "react-router-dom";
import { Offline, Online, Detector } from "react-detect-offline";

import WifiConnected from "../../assets/img/icons/wifiConnected.svg";
import WifiDisconnected from "../../assets/img/icons/wifiDisconnected.svg";


const TestNetworkConnection = () => {

    return (
        <>
            <Detector
                render={({ online }) => (
                    <div className={online ? "normal" : "warning"}>
                        <div className="network-icon">
                            <img className="network-img" style={{ display: online ? 'block' : 'none' }} src={WifiConnected} />
                            <img className="network-img" style={{ display: !online ? 'block' : 'none' }} src={WifiDisconnected} />
                        </div>
                        <div className="title-testicons">
                            {
                                online
                                    ?
                                    <span>Your network tested successfully</span>
                                    :
                                    <span>No internet connection</span>
                            }
                        </div>
                    </div>
                )}
            />
        </>
    );
};

export default withRouter(TestNetworkConnection);
