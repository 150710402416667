import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { Form, Col, InputNumber, Row, Button, TimePicker, Divider, DatePicker, Select } from "antd";
import moment from 'moment-timezone';
import TimezoneSelect from 'react-timezone-select';
import { createOTSCLass, getAllClasses, getOtsClass, updateOTSCLass } from "../../actions/actions";
import _ from 'underscore';


const CreateOTSClasses = (props) => {
  const { tokenExpire } = props;
  const item = props.match.params.classId === "new" ? null : props.location.state ? props.location.state.item.OTS : props.otsClass ? props.otsClass[0] : null;

  useEffect(() => {
    if (tokenExpire) {
      props.history.push("/")
    }
  }, [tokenExpire]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const tzCurrent = moment.tz.guess();
  const { pending, otsClassStatus } = props;
  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [startTimeMoment, setStartTimeMoment] = useState(item ? moment(item.startDateTime) : null);
  const [endTimeMoment, setEndTimeMoment] = useState(item ? moment(item.endDateTime) : null);
  const [selectedClassName, setSelectedClassName] = useState(item ? item.name : null);

  const [estimatedIncome, setEstimatedIncome] = useState(item ? item.price * item.maxParticipants : 0);
  const [deductIncome, setDeductIncome] = useState(item ? ([(item.maxParticipants * item.price) / 100] * 20).toFixed(2) : 0);
  const [finalIncome, setFinalIncome] = useState(item ? [(estimatedIncome) - (deductIncome)] : 0);
  const [totalStudents, setTotalStudents] = useState(item ? item.maxParticipants : 15);
  const [pricePerStudent, setPricePerStudent] = useState(item ? item.price : 0);
  const [duration, setDuration] = useState(0);
  const [offset, setOffset] = useState("");
  const [sTime, setsTime] = useState(false);
  const { loginData, classes } = props;
  const { Option } = Select;
  const date = new Date();
  //TODO: this date should be respected to timezone
  const currentDate = offset ? moment().tz(offset) : moment();
  const [assignDate, setAssignDate] = useState(null);

  let selectedDate = null;

  const selectedDateMomentRef = useRef(null);
  const [form] = Form.useForm();
  const [number, setNumber] = useState({
    value: null,
  });
  const [selectedCurrency, setSelectedCurrency] = useState(null);

  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 18,
    },
  };

  useEffect(() => {
    const data = {
      token: loginData.access,
      teacherId: loginData.userID
    }
    props.getAllClasses(data);
    if (props.match.params.classId !== "new") {
      props.getOtsClass({
        otsId: props.match.params.classId,
        token: loginData.access
      });
    }
  }, []);

  useEffect(() => {
    if (otsClassStatus === true) {
      props.history.push("/");
    }
  }, [otsClassStatus]);

  const startTimeOnChange = async (time) => {

    if (time && time.isBefore(moment()) && selectedDateMomentRef.current && selectedDateMomentRef.current.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
      setStartTimeMoment(null)
      form.setFieldsValue({
        startTime: '',
      });
      return;
    };
    if (endTimeMoment && time) {
      const newTime = moment(time).format("HH:mm");
      const newEndTime = moment(endTimeMoment).format("HH:mm");
      if (!moment(newTime, "HH:mm").isBefore(moment(newEndTime, "HH:mm"))) {
        form.setFieldsValue({
          startTime: '',
        });
        return
      }
    }
    setStartTimeMoment(time)
  };

  const endTimeOnChange = (time) => {
    if (time && time.isBefore(moment()) && selectedDateMomentRef.current && selectedDateMomentRef.current.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
      setEndTimeMoment(null)
      form.setFieldsValue({
        endTIme: '',
      });
      return;
    }
    if (startTimeMoment && time) {
      const newTime = moment(time).format("HH:mm");
      const newStartTime = moment(startTimeMoment).format("HH:mm");
      if (!moment(newTime, "HH:mm").isAfter(moment(newStartTime, "HH:mm"))) {
        form.setFieldsValue({
          endTIme: '',
        });
        return
      } else {
        console.log("else is same or before")
      }
    }
    setEndTimeMoment(time);
  };

  const maxStudentOnChange = (value) => {
    setNumber({ ...validatePrimeNumber(value), value });
    const estimateIncome = value * pricePerStudent;
    const afterDeduction = [(value * pricePerStudent) / (100)] * 20;
    setEstimatedIncome(estimateIncome.toFixed(2));
    setTotalStudents(value)
    setDeductIncome(afterDeduction.toFixed(2));
    setFinalIncome((estimateIncome - afterDeduction).toFixed(2));
  };

  function validatePrimeNumber(number) {
    if (number > 0 && number < 16) {
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }

    return {
      validateStatus: 'error',
      errorMsg: 'Please add number from 1 to 15',
    };
  }

  const priceOnChange = (value) => {
    const estimateIncome = value * totalStudents;
    const afterDeduction = [(value * totalStudents) / (100)] * 20;
    setEstimatedIncome(estimateIncome.toFixed(2));
    setPricePerStudent(value)
    setDeductIncome(afterDeduction.toFixed(2));
    setFinalIncome((estimateIncome - afterDeduction).toFixed(2));
  };

  const timezoneOnChange = (timezone) => {
    moment.tz.setDefault(timezone.value);
    setSelectedTimezone(timezone);
    setOffset(timezone.value);
    if (selectedDateMomentRef.current && selectedDateMomentRef.current.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
      if (startTimeMoment && startTimeMoment.isBefore(moment())) {
        setStartTimeMoment(null);
        form.setFieldsValue({
          startTime: '',
        });
      };
      if (endTimeMoment && endTimeMoment.isBefore(moment())) {
        setEndTimeMoment(null);
        form.setFieldsValue({
          endTIme: '',
        });
      }
    }

  };


  const onFinish = (values) => {
    var moment = require('moment-timezone');

    const time = moment(values.startTime).format("HH:mm");
    const eTime = moment(values.endTIme).format("HH:mm");
    const date = moment(values.classDate).format("YYYY-MM-DD");
    const newOffset = offset ? offset : selectedTimezone;
    const timeZoneFormatted = newOffset && moment().tz(newOffset) ?
      moment().tz(newOffset).format('Z') : moment().format('Z');
    const addTime = `${date}T${time}:00${timeZoneFormatted}`;
    const endTime = `${date}T${eTime}:00${timeZoneFormatted}`;
    const totalParticipants = values.totalStudents ? values.totalStudents : totalStudents;

    const formData = {
      name: values.classesName[0],
      classID: values.classesName[1],
      startDateTime: addTime,
      endDateTime: endTime,
      duration: endTimeMoment.diff(startTimeMoment, 'seconds') + 1,
      timeZone: timeZoneFormatted,
      maxParticipants: totalParticipants,
      IsWaitlistAvailable: true,
      currency: values.currency,
      price: values.price ? values.price : pricePerStudent,
      token: loginData.access,
      otsId: item ? item.id : null
    };
    if (item && totalParticipants > 0 && totalParticipants < 16) {
      props.updateOTSCLass(formData);
    } else if (totalParticipants > 0 && totalParticipants < 16) {
      props.createOTSCLass(formData);
    }
  };

  //Start time handling
  let selectedHourStartTime = null;
  let currentDateNow = offset ? moment().tz(offset) : moment();

  const onOpenChangeStartTime = (isOpen) => {
    isOpen === false && (selectedHourStartTime = null);
  }

  const selectStartTime = (value) => {
    //Check selected date if its today then this is needed
    selectedHourStartTime = value;
  }

  const getDisabledMinutesStartTime = () => {
    if ((!selectedDateMomentRef.current || selectedDateMomentRef.current.format('YYYY-MM-DD') != currentDateNow.format('YYYY-MM-DD') || (selectedHourStartTime && selectedHourStartTime.format('HH') != currentDateNow.format('HH'))) && !endTimeMoment) return [];

    let disabledMinutesEndingTimeSpecific = [], disabledMinutesSameHourSpecific = [];

    if (endTimeMoment && ((selectedHourStartTime && selectedHourStartTime.format('HH') === endTimeMoment.format('HH')))) {
      let endingMinutes = endTimeMoment.format('mm');
      disabledMinutesEndingTimeSpecific = _.range(Number(endingMinutes) + 1, 60);
    }
    if (selectedDateMomentRef.current && selectedDateMomentRef.current.format('YYYY-MM-DD') === currentDateNow.format('YYYY-MM-DD') && selectedHourStartTime && currentDateNow.format('HH') === selectedHourStartTime.format('HH')) {
      let currentMinutes = currentDateNow.format('mm');
      disabledMinutesSameHourSpecific = _.range(Number(currentMinutes));
    }

    return [...new Set([...disabledMinutesEndingTimeSpecific, ...disabledMinutesSameHourSpecific])];
  }

  const getDisabledHoursStartTime = () => {

    if ((!selectedDateMomentRef.current || selectedDateMomentRef.current.format('YYYY-MM-DD') != currentDateNow.format('YYYY-MM-DD')) && !endTimeMoment) return [];

    let disabledHoursEndingTimeSpecific = [], disabledHoursSameDateSpecific = [];
    if (endTimeMoment) {
      let endingHours = endTimeMoment.format('HH');
      if (endingHours) {
        //+1 if same hour class 10:10 - 10:50
        disabledHoursEndingTimeSpecific = _.range(Number(endingHours) + 1, 24)
      }
    }

    if (selectedDateMomentRef.current && selectedDateMomentRef.current.format('YYYY-MM-DD') === currentDateNow.format('YYYY-MM-DD')) {
      let currentHours = currentDateNow.format('HH');
      disabledHoursSameDateSpecific = _.range(currentHours);
    }
    return [...new Set([...disabledHoursSameDateSpecific, ...disabledHoursEndingTimeSpecific])];
  }

  //End time handling
  let selectedHourEndTime = null;
  const onOpenChangeEndTime = (isOpen) => {
    isOpen === false && (selectedHourEndTime = null);
  }

  const selectEndTime = (value) => {
    selectedHourEndTime = value;
  }

  const getDisabledMinutesEndTime = () => {
    if ((!selectedDateMomentRef.current || selectedDateMomentRef.current.format('YYYY-MM-DD') != currentDateNow.format('YYYY-MM-DD')) && !selectedHourStartTime && !startTimeMoment) return [];


    let disabledMinutesStartingTimeSpecific = [], disabledMinutesSameHourSpecific = [];

    if (startTimeMoment && selectedHourEndTime && selectedHourEndTime.format('HH') === startTimeMoment.format('HH')) {
      let startingMinutes = startTimeMoment.format('mm');
      disabledMinutesStartingTimeSpecific = _.range(Number(startingMinutes) + 1);
    }

    if (selectedDateMomentRef.current && selectedDateMomentRef.current.format('YYYY-MM-DD') === currentDateNow.format('YYYY-MM-DD') && selectedHourEndTime && currentDateNow.format('HH') === selectedHourEndTime.format('HH')) {
      let currentMinutes = currentDateNow.format('mm');
      disabledMinutesSameHourSpecific = _.range(Number(currentMinutes));
    }

    return [...new Set([...disabledMinutesStartingTimeSpecific, ...disabledMinutesSameHourSpecific])];
  }

  const getDisabledHoursEndTime = () => {

    if ((!selectedDateMomentRef.current || selectedDateMomentRef.current.format('YYYY-MM-DD') != currentDateNow.format('YYYY-MM-DD')) && !startTimeMoment) return [];

    let disabledHoursStartTimeSpecific = [], disabledHoursSameDateSpecific = [];
    if (startTimeMoment) {
      let startingHours = startTimeMoment.format('HH');
      if (startingHours) {
        //+1 if same hour class 10:10 - 10:50
        disabledHoursStartTimeSpecific = _.range(Number(startingHours))
      }
    }

    if (selectedDateMomentRef.current && selectedDateMomentRef.current.format('YYYY-MM-DD') === currentDateNow.format('YYYY-MM-DD')) {
      let currentHours = currentDateNow.format('HH');
      disabledHoursSameDateSpecific = _.range(currentHours);
    }
    return [...new Set([...disabledHoursSameDateSpecific, ...disabledHoursStartTimeSpecific])];

  }

  const onClassDateChange = (current) => {
    selectedDateMomentRef.current = current;
    //Reset start Time and End time if current date
    if (current && current.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
      if (startTimeMoment && startTimeMoment.isBefore(moment())) {
        setStartTimeMoment(null)
        form.setFieldsValue({
          startTime: '',
        });
      };
      if (endTimeMoment && endTimeMoment.isBefore(moment())) {
        setEndTimeMoment(null);
        form.setFieldsValue({
          endTIme: '',
        });
      }
    }
    selectedDate = current && { date: current.toDate().getDate(), month: current.toDate().getMonth(), year: current.toDate().getFullYear() }
    setAssignDate(current);
  }

  const handleChange = (value) => {
    setSelectedClassName(value);
  }

  const handleChangeCurrency = value => {
    setSelectedCurrency(value)
  };

  return (
    <div>

      <div className="ots-home">
        <Row>
          <p className="ots-heading">{props.location.state || props.match.params.classId !== "new" ? "Update OTS Class" : "Create OTS Class"}</p>
        </Row>
        <Form
          form={form}
          {...layout}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Row
          >
            <Col
              className="gutter-row"
              xs={24}
              md={16}
              lg={16}
            //  span={8}
            >
              <Row>
                <Col xs={24} md={12}>

                </Col>
                <Col xs={24} md={12}>

                </Col>
              </Row>
              <Row>
                <Col xs={24} md={12}>
                  <div className={'form-input-item'}>

                    <p className="ots-input-heading">OTS Class Name</p>
                    <Form.Item
                      name="classesName"
                      className="login-input"
                      rules={[
                        {
                          required: true,
                          message: "Please select class",
                        }
                      ]}
                      initialValue={item ? [item.name, item.id] : null}
                    >
                      <Select
                        showSearch
                        className="ots-name-select"
                        defaultValue={item ? [item.name, item.id] : selectedClassName}
                        style={{
                          width: "100%",
                        }}
                        placeholder="Select a class"
                        optionFilterProp="children"
                        onChange={handleChange}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {classes ?
                          classes.map((val) => (

                            <Option value={[val.name, val.id]}>{val.name}</Option>
                          )) : null

                        }
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <div className={'form-input-item'}>

                    <p className="ots-input-heading">Class Date</p>

                    <Form.Item
                      name="classDate"
                      className="login-input"
                      rules={[
                        {
                          required: true,
                          message: "Please add a Date!",
                          type: "object",
                        },
                      ]}
                      initialValue={item ? moment(item.startDateTime) : null}
                      style={{ marginBottom: 0 }}
                    >
                      <DatePicker
                        style={{
                          height: 50,
                          border: "none",
                          backgroundColor: "#d5d5d5",
                          width: "100%",
                        }}
                        clearIcon={false}
                        defaultValue={assignDate ? assignDate : item ? moment(item.startDateTime) : null}
                        className="ots-datetimepicker"
                        disabledDate={current => {
                          if (!item) {
                            if (
                              current.diff(currentDate, 'minutes') >= 0 &&
                              current.diff(currentDate, 'days') < 7)
                              return false;
                            return true;
                          } else {
                            if (
                              current.diff(currentDate, 'days') >= 0 &&
                              current.diff(currentDate, 'days') < 6)
                              return false;
                            return true;
                          }
                        }}
                        onChange={onClassDateChange}
                      />
                    </Form.Item>
                    <p className="ots-date-text">
                      No more than 7 days in the future
                    </p>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs={24} md={12}>
                  <div className={'form-input-item'}>
                    <p className="ots-input-heading">Start Time</p>
                    <Form.Item
                      name="startTime"
                      className="login-input"
                      rules={[
                        {
                          required: true,
                          message: "Please add Start Time!",
                          type: "object",
                        },
                      ]}
                      initialValue={item ? moment(item.startDateTime).tz(tzCurrent) : null}
                    >
                      <TimePicker
                        style={{
                          height: 50,
                          border: "none",
                          backgroundColor: "#d5d5d5",
                          width: "100%",
                        }}
                        onOpenChange={onOpenChangeStartTime}
                        onSelect={selectStartTime}
                        showNow={false}
                        className="ots-datetimepicker"
                        format="HH:mm"
                        clearIcon={false}
                        defaultValue={item ? moment(item.startDateTime).tz(tzCurrent) : endTimeMoment}
                        onChange={startTimeOnChange}
                        disabledHours={getDisabledHoursStartTime}
                        disabledMinutes={getDisabledMinutesStartTime}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <div className={'form-input-item'}>

                    <p className="ots-input-heading">End Time</p>

                    <Form.Item
                      name="endTIme"
                      className="login-input"
                      rules={[
                        {
                          required: true,
                          message: "Please add an End Time!",
                          type: "object",
                        },
                      ]}
                      initialValue={item ? moment(item.endDateTime).tz(tzCurrent) : null}
                    >
                      <TimePicker
                        style={{
                          height: 50,
                          border: "none",
                          backgroundColor: "#d5d5d5",
                          width: "100%",
                        }}
                        onOpenChange={onOpenChangeEndTime}
                        onSelect={selectEndTime}
                        showNow={false}
                        className="ots-datetimepicker"
                        defaultValue={item ? moment(item.endDateTime).tz(tzCurrent) : endTimeMoment}
                        format="HH:mm"
                        onChange={endTimeOnChange}
                        clearIcon={false}
                        disabledHours={getDisabledHoursEndTime}
                        disabledMinutes={getDisabledMinutesEndTime}
                      />
                    </Form.Item>
                  </div>


                </Col>
              </Row>

              <Row>
                <Col xs={24} md={12}>
                  <div className={'form-input-item'}>

                    <p className="ots-input-heading">Time Zone</p>

                    <div className="time-zone">
                      <TimezoneSelect
                        value={selectedTimezone}
                        className="timezone-select"
                        onChange={timezoneOnChange
                        }
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <div className={'form-input-item'}>

                    <p className="ots-input-heading">Max Students</p>

                    <Form.Item
                      name="totalStudents"
                      className="login-input"
                      validateStatus={number.validateStatus}
                      help={number.errorMsg}
                      initialValue={item ? item.maxParticipants : null}
                    >
                      <InputNumber
                        style={{
                          height: 50,
                          border: "none",
                          backgroundColor: "#d5d5d5",
                          width: "100%",
                          textAlign: "center"
                        }}
                        defaultValue={item ? item.maxParticipants : totalStudents}
                        type="number"
                        className="ots-student-input"
                        placeholder="15"
                        onChange={maxStudentOnChange}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs={24} md={12}>
                  <div className={'form-input-item'}>

                    <p className="ots-input-heading">Price Per Student</p>

                    <Form.Item
                      name="price"
                      className="login-input"
                      style={{ marginBottom: 0 }}
                      initialValue={item ? item.price : null}
                    >
                      <InputNumber
                        style={{
                          height: 50,
                          border: "none",
                          backgroundColor: "#d5d5d5",
                          width: "100%",
                          textAlign: "center"
                        }}
                        defaultValue={item ? item.price : pricePerStudent}
                        onChange={priceOnChange}
                        min={0}
                        max={1000}
                        type="number"
                        className="ots-student-input"
                        placeholder="$0"
                      />
                    </Form.Item>

                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <div className={'form-input-item'}>

                    <p className="ots-input-heading">Currency</p>

                    <Form.Item
                      name="currency"
                      className="login-input"
                      rules={[
                        {
                          required: true,
                          message: "Please select currency",
                        },
                      ]}
                      initialValue={item ? item.currency : null}
                    >
                      <Select
                        showSearch
                        className="ots-currency-select"
                        style={{
                          width: "100%",
                        }}
                        defaultValue={item ? item.currency : selectedCurrency}
                        placeholder="Select Currency"
                        optionFilterProp="children"
                        onChange={handleChangeCurrency}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >

                        <Option value="usd">United States Dollar</Option>
                        <Option value="aud">Australian Dollar</Option>
                        <Option value="nzd">New Zealand Dollar</Option>

                      </Select>
                    </Form.Item>
                  </div>
                </Col>
              </Row>

            </Col>

            <Col
              className="gutter-row"
              xs={24}
              md={8}
              lg={8}
              style={{ display: "flex" }}
            >
              <div
                className={'create-class-info'}
                style={{
                  backgroundColor: "#eceeee",
                  height: "auto",
                  width: "100%",
                }}
              >
                <Row style={{ marginTop: 30 }}>
                  <Col span={16}>
                    <p className="field-estimate">Estimated Income</p>
                  </Col>
                  <Col span={8}>
                    <p className="field-estimate">{`$${estimatedIncome}`}</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={16}>
                    <p className="field-estimate">20% Fee Deduct</p>
                  </Col>
                  <Col span={8}>
                    <p className="field-estimate">{`$${deductIncome}`}</p>
                  </Col>
                </Row>
                <Divider />
                <Row>
                  <Col span={16}>
                    <p
                      className="field-estimate"
                      style={{ color: "#8fb594", marginTop: 0 }}
                    >
                      You'll earn
                    </p>
                  </Col>
                  <Col span={8}>
                    <p
                      className="field-estimate"
                      style={{ color: "#8fb594", marginTop: 0 }}
                    >
                      {`$${finalIncome}`}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row style={{}}>
            <div
              className="footer-button"
              style={{ width: "100%", paddingRight: 30, paddingTop: 10 }}
            >
              <Button
                style={{
                  backgroundColor: "#8FB594",
                  borderColor: "#8FB594",
                  minWidth: 200,
                  padding: 20,
                  color: "#383838",
                }}
                loading={pending}
                type="primary"
                htmlType="submit"
                className="activate-ots-button"
              >
                {props.location.state ? "Update OTS" : "Activate OTS"}
              </Button>
            </div>
          </Row>
        </Form>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllClasses: (data) => dispatch(getAllClasses(data)),
    createOTSCLass: (data) => dispatch(createOTSCLass(data)),
    updateOTSCLass: (data) => dispatch(updateOTSCLass(data)),
    getOtsClass: (data) => dispatch(getOtsClass(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    loginData: state.loginData,
    otsClasses: state.otsClasses,
    otsClass: state.otsClass,
    classes: state.classes,
    pending: state.pending,
    otsClassStatus: state.otsClassStatus,
    tokenExpire: state.tokenExpire,
    isLogout: state.isLogout,
  };
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CreateOTSClasses);