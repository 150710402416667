import React, { useState, useEffect, useCallback } from "react";
import { message } from "antd";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import Room from "./Room";
import { getData } from "../../apis/apis";
import {
  endClass,
  loading,
  playVideo,
  redirectRoute,
  roomJoined,
} from "../../actions/actions";

const JoinRoom = (props) => {
  const { isRoomEnded, isStartClass, otsRoomData } = props;
  const [token, setToken] = useState(null);

  useEffect(() => {
    if (isStartClass) {
      async function joinRoom() {
        const roomName = otsRoomData.OTS.roomID;
        const userName = props.userName;
        let data = await getData(roomName, userName);
        data = await data.json();
        if (data.Token) {
          props.roomJoined(true);
          props.loading(false);
          setToken(data.Token);
        } else {
          message.error({
            content: "Class is expired. Please start your class again",
          });
          props.loading(false);
          props.redirectRoute(true);
        }
      }
      joinRoom();
    }
  }, [props.isStartClass]);

  const returnToLobby = useCallback((event) => {
    setToken(null);
  }, []);

  useEffect(() => {
    if (isRoomEnded === true) {
      props.history.push("/main");
    }
  }, [isRoomEnded]);

  return (
    <div>
      {token ? (
        <Room
          roomName={props.roomName}
          token={token}
          returnToLobby={returnToLobby}
        />
      ) : null}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    loading: (data) => dispatch(loading(data)),
    endClass: (data) => dispatch(endClass(data)),
    playVideo: (data) => dispatch(playVideo(data)),
    roomJoined: (data) => dispatch(roomJoined(data)),
    redirectRoute: (data) => dispatch(redirectRoute(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    loginData: state.loginData,
    isRoomEnded: state.isRoomEnded,
    isStartClass: state.isStartClass,
    createdClass: state.createdClass,
    otsRoomData: state.otsRoomData,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(JoinRoom);
