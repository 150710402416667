export const POST_LOGINDATA_REQUEST = "POST_LOGINDATA_REQUEST";
export const POST_LOGINDATA_SUCCESS = "POST_LOGINDATA_SUCCESS";
export const POST_LOGINDATA_FAILURE = "POST_LOGINDATA_FAILURE";

export const POST_CREATECLASS_REQUEST = "POST_CREATECLASS_REQUEST";
export const POST_CREATECLASS_SUCCESS = "POST_CREATECLASS_SUCCESS";
export const POST_CREATECLASS_FAILURE = "POST_CREATECLASS_FAILURE";

export const POST_ENDCLASS_REQUEST = "POST_ENDCLASS_REQUEST";
export const POST_ENDCLASS_SUCCESS = "POST_ENDCLASS_SUCCESS";
export const POST_ENDCLASS_FAILURE = "POST_ENDCLASS_FAILURE";

export const CHANGE_STUDENT_RULE_REQUEST = "CHANGE_STUDENT_RULE_REQUEST";
export const CHANGE_STUDENT_RULE_SUCCESS = "CHANGE_STUDENT_RULE_SUCCESS";
export const CHANGE_STUDENT_RULE_FAILURE = "CHANGE_STUDENT_RULE_FAILURE";

export const ENABLE_SPEAKALL_REQUEST = "ENABLE_SPEAKALL_REQUEST";
export const ENABLE_SPEAKALL_SUCCESS = "ENABLE_SPEAKALL_SUCCESS";
export const ENABLE_SPEAKALL_FAILURE = "ENABLE_SPEAKALL_FAILURE";

export const UPDATE_RULES = "UPDATE_RULES";
export const UPDATE_RULES_SUCCESS = "UPDATE_RULES_SUCCESS";
export const UPDATE_RULES_FAILURE = "UPDATE_RULES_FAILURE";


export const GET_ALL_OTS_CLASSES_REQUEST = "GET_ALL_OTS_CLASSES_REQUEST";
export const GET_ALL_OTS_CLASSES_SUCCESS = "GET_ALL_OTS_CLASSES_SUCCESS";
export const GET_ALL_OTS_CLASSES_FAILURE = "GET_ALL_OTS_CLASSES_FAILURE";

export const GET_ALL_CLASSES_REQUEST = "GET_ALL_CLASSES_REQUEST";
export const GET_ALL_CLASSES_SUCCESS = "GET_ALL_CLASSES_SUCCESS";
export const GET_ALL_CLASSES_FAILURE = "GET_ALL_CLASSES_FAILURE";

export const GET_OTS_CLASS_REQUEST = "GET_OTS_CLASS_REQUEST";
export const GET_OTS_CLASS_SUCCESS = "GET_OTS_CLASS_SUCCESS";
export const GET_OTS_CLASS_FAILURE = "GET_OTS_CLASS_FAILURE";

export const CREATE_OTS_REQUEST = "CREATE_OTS_REQUEST";
export const CREATE_OTS_SUCCESS = "CREATE_OTS_SUCCESS";
export const CREATE_OTS_FAILURE = "CREATE_OTS_FAILURE";

export const UPDATE_OTS_REQUEST = "UPDATE_OTS_REQUEST";
export const UPDATE_OTS_SUCCESS = "UPDATE_OTS_SUCCESS";
export const UPDATE_OTS_FAILURE = "UPDATE_OTS_FAILURE";

export const CREATE_OTSROOM_REQUEST = "CREATE_OTSROOM_REQUEST";
export const CREATE_OTSROOM_SUCCESS = "CREATE_OTSROOM_SUCCESS";
export const CREATE_OTSROOM_FAILURE = "CREATE_OTSROOM_FAILURE";

export const END_OTSROOM_REQUEST = "END_OTSROOM_REQUEST";
export const END_OTSROOM_SUCCESS = "END_OTSROOM_SUCCESS";
export const END_OTSROOM_FAILURE = "END_OTSROOM_FAILURE";

export const OTS_CLASS_STATUS = "OTS_CLASS_STATUS";

export const REFRESH_TOKEN = "REFRESH_TOKEN";

export const GET_PLAYVIDEO_ACCESS = "GET_PLAYVIDEO_ACCESS";

export const CHANGE_LOGIN_STATE = "CHANGE_LOGIN_STATE";

export const STUDENTS_ID_DATA = "STUDENTS_ID_DATA";

export const REMOVE_PARTICIPANT = "REMOVE_PARTICIPANT";
export const ADD_PARTICIPANT = "ADD_PARTICIPANT";

export const REMOVE_ID = "REMOVE_ID";
export const ADD_ID = "ADD_ID";

export const STORE_ROOM_TOKEN = "STORE_ROOM_TOKEN";

export const MUTE_UNMUTE_VIDEOPLAYER = "MUTE_UNMUTE_VIDEOPLAYER";

export const MUTE_UNMUTE_TEACHERMIC = "MUTE_UNMUTE_TEACHERMIC";

export const VIDEO_PLAYED = "VIDEO_PLAYED";

export const NEW_PARTICIPANT_CONNECTED = "NEW_PARTICIPANT_CONNECTED";

export const TOTAL_PARTICIPANT = "TOTAL_PARTICIPANT";

export const START_CLASS = "START_CLASS";

export const EXIT_ROOM = "EXIT_ROOM";

export const SYNC_VIDEO = "SYNC_VIDEO";

export const MUTE_ALL_STUDENTS = "MUTE_ALL_STUDENTS";

export const ROOM_JOINED = "ROOM_JOINED";

export const LOADING = "LOADING";

export const ADD_LOCAL_PARTICIPANT_ID = "ADD_LOCAL_PARTICIPANT_ID";

export const STORE_ROOM_DATA = "STORE_ROOM_DATA";

export const SPEAKTOALL_STATUS = "SPEAKTOALL_STATUS";

export const COMMUNICATION_WAY = "COMMUNICATION_WAY";

export const REDIRECT_ROUTE = "REDIRECT_ROUTE";

export const KEEPME_LOGGEDIN = "KEEPME_LOGGEDIN";

export const REDIRECT_TO_LOGIN = "REDIRECT_TO_LOGIN";

export const LOGOUT_USER = "LOGOUT_USER";

export const FETCH_TEACHER_PROFILE_DETAILS_REQUEST = "FETCH_TEACHER_PROFILE_DETAILS_REQUEST";
export const FETCH_TEACHER_PROFILE_DETAILS_SUCCESS = "FETCH_TEACHER_PROFILE_DETAILS_SUCCESS";
export const FETCH_TEACHER_PROFILE_DETAILS_FAILURE = "FETCH_TEACHER_PROFILE_DETAILS_FAILURE";

export const UPDATE_TEACHER_PASSWORD_REQUEST = "UPDATE_TEACHER_PASSWORD_REQUEST";
export const UPDATE_TEACHER_PASSWORD_SUCCESS = "UPDATE_TEACHER_PASSWORD_SUCCESS";
export const UPDATE_TEACHER_PASSWORD_FAILURE = "UPDATE_TEACHER_PASSWORD_FAILURE";

export const TEACHER_PROFILE_BIO_UPDATE_REQUEST = "TEACHER_PROFILE_BIO_UPDATE_REQUEST";
export const TEACHER_PROFILE_BIO_UPDATE_SUCCESS = "TEACHER_PROFILE_BIO_UPDATE_SUCCESS";
export const TEACHER_PROFILE_BIO_UPDATE_FAILURE = "TEACHER_PROFILE_BIO_UPDATE_FAILURE";

export const TEACHER_PROFILE_UPDATE_REQUEST = "TEACHER_PROFILE_UPDATE_REQUEST";
export const TEACHER_PROFILE_UPDATE_SUCCESS = "TEACHER_PROFILE_UPDATE_SUCCESS";
export const TEACHER_PROFILE_UPDATE_FAILURE = "TEACHER_PROFILE_UPDATE_FAILURE";

export const TEACHER_PROFILE_IMAGE_UPDATE_REQUEST = "TEACHER_PROFILE_IMAGE_UPDATE_REQUEST";
export const TEACHER_PROFILE_IMAGE_UPDATE_SUCCESS = "TEACHER_PROFILE_IMAGE_UPDATE_SUCCESS";
export const TEACHER_PROFILE_IMAGE_UPDATE_FAILURE = "TEACHER_PROFILE_IMAGE_UPDATE_FAILURE";

export const GET_TEACHER_PERFORMANCE_REQUEST = "GET_TEACHER_PERFORMANCE_REQUEST";
export const GET_TEACHER_PERFORMANCE_SUCCESS = "GET_TEACHER_PERFORMANCE_SUCCESS";
export const GET_TEACHER_PERFORMANCE_FAILURE = "GET_TEACHER_PERFORMANCE_FAILURE";

export const AUDIO_TEST = "AUDIO_TEST";

export const OPEN_ZOOM_MODAL = "OPEN_ZOOM_MODAL";

export const GET_TEACHER_EARNINGS_REQUEST = "GET_TEACHER_EARNINGS_REQUEST";
export const GET_TEACHER_EARNINGS_SUCCESS = "GET_TEACHER_EARNINGS_SUCCESS";
export const GET_TEACHER_EARNINGS_FAILURE = "GET_TEACHER_EARNINGS_FAILURE";

export const GET_ALL_SIMPLE_CLASSES_REQUEST = "GET_ALL_SIMPLE_CLASSES_REQUEST";
export const GET_ALL_SIMPLE_CLASSES_SUCCESS = "GET_ALL_SIMPLE_CLASSES_SUCCESS";
export const GET_ALL_SIMPLE_CLASSES_FAILURE = "GET_ALL_SIMPLE_CLASSES_FAILURE";