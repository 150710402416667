import { all, put, call, select, takeEvery } from "redux-saga/effects";

import apis from "../apis/apis";
import * as actions from "../actions/actionTypes";

export function* postLoginDataSaga(payload) {
  try {
    const { data, statusCode } = yield call(apis.postLoginData, payload.payload);
    if (data) {
      yield put({ type: actions.POST_LOGINDATA_SUCCESS, payload: data });
    } else if (statusCode) {
      yield put({ type: actions.POST_LOGINDATA_FAILURE, payload: statusCode });
    }
    else {
      yield put({ type: actions.POST_LOGINDATA_FAILURE, payload: statusCode });
    }
  } catch (error) {
    yield put({ type: actions.POST_LOGINDATA_FAILURE, payload: error });
  }
}

export function* createClassSaga(payload) {
  try {
    const { data } = yield call(apis.createClass, payload.payload);
    if (data) {
      yield put({ type: actions.POST_CREATECLASS_SUCCESS, payload: data });
    } else {
      yield put({ type: actions.POST_CREATECLASS_FAILURE });
    }
  } catch (error) {
    yield put({ type: actions.POST_CREATECLASS_FAILURE, payload: error });
  }
}

export function* endClassSaga(payload) {
  try {
    const { data } = yield call(apis.endClass, payload.payload);
    if (data) {
      yield put({ type: actions.POST_ENDCLASS_SUCCESS, payload: data });
    }
  } catch (error) {
    yield put({ type: actions.POST_ENDCLASS_FAILURE, payload: error });
  }
}

export function* changeStudentRulesSaga(payload) {
  try {
    const { data } = yield call(apis.changeRules, payload.payload);
    if (data) {
      yield put({ type: actions.CHANGE_STUDENT_RULE_SUCCESS, payload: data });
    } else {
      yield put({ type: actions.CHANGE_STUDENT_RULE_FAILURE });
    }
  } catch (error) {
    yield put({ type: actions.CHANGE_STUDENT_RULE_FAILURE, payload: error });
  }
}

export function* enableSpeakAllSaga(payload) {
  try {
    const { data } = yield call(apis.speakAll, payload.payload);
    if (data) {
      yield put({ type: actions.ENABLE_SPEAKALL_SUCCESS, payload: data });
    } else {
      yield put({ type: actions.ENABLE_SPEAKALL_FAILURE });
    }
  } catch (error) {
    yield put({ type: actions.ENABLE_SPEAKALL_FAILURE, payload: error });
  }
}

export function* getAllOtsClassesSaga(payload) {
  try {
    const { data } = yield call(apis.allOTSClasses, payload.payload);
    if (data) {
      yield put({ type: actions.GET_ALL_OTS_CLASSES_SUCCESS, payload: data });
    } else {
      yield put({ type: actions.GET_ALL_OTS_CLASSES_FAILURE });
    }
  } catch (error) {
    yield put({ type: actions.GET_ALL_OTS_CLASSES_FAILURE, payload: error });
  }
}

export function* getOtsClassSaga(payload) {
  try {
    const { data } = yield call(apis.getOtsClass, payload.payload);
    if (data) {
      yield put({ type: actions.GET_OTS_CLASS_SUCCESS, payload: data });
    } else {
      yield put({ type: actions.GET_OTS_CLASS_FAILURE });
    }
  } catch (error) {
    yield put({ type: actions.GET_OTS_CLASS_FAILURE, payload: error });
  }
}

export function* getAllClassesSaga(payload) {
  try {
    const { data } = yield call(apis.allClasses, payload.payload);
    if (data) {
      yield put({ type: actions.GET_ALL_CLASSES_SUCCESS, payload: data });
    } else {
      yield put({ type: actions.GET_ALL_CLASSES_FAILURE });
    }
  } catch (error) {
    yield put({ type: actions.GET_ALL_CLASSES_FAILURE, payload: error });
  }
}

export function* createOTSClassSaga(payload) {
  try {
    const { data } = yield call(apis.createOTSClass, payload.payload);
    if (data) {
      yield put({ type: actions.CREATE_OTS_SUCCESS, payload: data });
    } else {
      yield put({ type: actions.CREATE_OTS_FAILURE });
    }
  } catch (error) {
    yield put({ type: actions.CREATE_OTS_FAILURE, payload: error });
  }
}

export function* updateOTSClassSaga(payload) {
  try {
    const { data } = yield call(apis.updateOTSClass, payload.payload);
    if (data) {
      yield put({ type: actions.UPDATE_OTS_SUCCESS, payload: data });
    } else {
      yield put({ type: actions.UPDATE_OTS_FAILURE });
    }
  } catch (error) {
    yield put({ type: actions.UPDATE_OTS_FAILURE, payload: error });
  }
}

export function* createOTSRoomSaga(payload) {
  try {
    const { data } = yield call(apis.createOTSRoom, payload.payload);
    if (data) {
      yield put({ type: actions.CREATE_OTSROOM_SUCCESS, payload: data });
    } else {
      yield put({ type: actions.CREATE_OTSROOM_FAILURE });
    }
  } catch (error) {
    yield put({ type: actions.CREATE_OTSROOM_FAILURE, payload: error });
  }
}

export function* endOTSRoomSaga(payload) {
  try {
    const { data } = yield call(apis.endOTSRoom, payload.payload);
    if (data) {
      yield put({ type: actions.END_OTSROOM_SUCCESS, payload: data });
    } else {
      yield put({ type: actions.END_OTSROOM_FAILURE });
    }
  } catch (error) {
    yield put({ type: actions.END_OTSROOM_FAILURE, payload: error });
  }
}

const getRoomData = (state) => state.roomData;
const getMuteData = (state) => state.muteStudents;
const getSpeakData = (state) => state.speakToAllEnabled;

export function* updateRulesSaga(payload) {
  try {
    let roomData = yield select(getRoomData);
    let muteData = yield select(getMuteData);
    let speakData = yield select(getSpeakData);
    const newData = { payload, roomData };
    if (muteData || speakData) {
      const { data } = yield call(apis.updateRules, newData);
      yield put({
        type: actions.MUTE_UNMUTE_TEACHERMIC,
        payload: true,
      });
      if (data) {
        yield put({ type: actions.UPDATE_RULES_SUCCESS, payload: data });
      } else {
        yield put({ type: actions.UPDATE_RULES_FAILURE });
      }
    }
  } catch (error) {
  }
}

export function* fetchTeacherProfileDetailsSaga(payload) {
  try {
    const { data } = yield call(apis.fetchTeacherDetails, payload.payload);
    if (data) {
      yield put({ type: actions.FETCH_TEACHER_PROFILE_DETAILS_SUCCESS, payload: data });
    } else {
      yield put({ type: actions.FETCH_TEACHER_PROFILE_DETAILS_FAILURE });
    }
  } catch (error) {
    yield put({ type: actions.FETCH_TEACHER_PROFILE_DETAILS_FAILURE, payload: error });
  }
}

export function* updateTeacherPasswordSaga(payload) {
  try {
    const { data } = yield call(apis.updateTeacherPassword, payload.payload);
    if (data) {
      yield put({ type: actions.UPDATE_TEACHER_PASSWORD_SUCCESS, payload: data });
    } else {
      yield put({ type: actions.UPDATE_TEACHER_PASSWORD_FAILURE });
    }
  } catch (error) {
    yield put({ type: actions.UPDATE_TEACHER_PASSWORD_FAILURE, payload: error });
  }
}


export function* teacherProfileBioUpdateSaga(payload) {
  try {
    const { data } = yield call(apis.teacherProfileBioUpdate, payload.payload);
    if (data) {
      yield put({ type: actions.TEACHER_PROFILE_BIO_UPDATE_SUCCESS, payload: data });
    } else {
      yield put({ type: actions.TEACHER_PROFILE_BIO_UPDATE_FAILURE });
    }
  } catch (error) {
    yield put({ type: actions.TEACHER_PROFILE_BIO_UPDATE_FAILURE, payload: error });
  }
}

export function* teacherProfileUpdateSaga(payload) {
  try {
    const { data } = yield call(apis.teacherProfileUpdate, payload.payload);
    if (data) {
      yield put({ type: actions.TEACHER_PROFILE_UPDATE_SUCCESS, payload: data });
    } else {
      yield put({ type: actions.TEACHER_PROFILE_UPDATE_FAILURE });
    }
  } catch (error) {
    yield put({ type: actions.TEACHER_PROFILE_UPDATE_FAILURE, payload: error });
  }
}

export function* teacherImageUpdateSaga(payload) {
  try {
    const { data } = yield call(apis.teacherImageUpdate, payload.payload);
    if (data) {
      yield put({ type: actions.TEACHER_PROFILE_IMAGE_UPDATE_SUCCESS, payload: data });
    } else {
      yield put({ type: actions.TEACHER_PROFILE_IMAGE_UPDATE_FAILURE });
    }
  } catch (error) {
    yield put({ type: actions.TEACHER_PROFILE_IMAGE_UPDATE_FAILURE, payload: error });
  }
}

export function* teacherPerformanceSaga(payload) {
  try {
    const { data } = yield call(apis.teacherPerformance, payload.payload);
    if (data) {
      yield put({ type: actions.GET_TEACHER_PERFORMANCE_SUCCESS, payload: data });
    } else {
      yield put({ type: actions.GET_TEACHER_PERFORMANCE_FAILURE });
    }
  } catch (error) {
    yield put({ type: actions.GET_TEACHER_PERFORMANCE_FAILURE, payload: error });
  }
}

export function* teacherEarningsSaga(payload) {
  try {
    const { data } = yield call(apis.teacherEarnings, payload.payload);
    if (data) {
      yield put({ type: actions.GET_TEACHER_EARNINGS_SUCCESS, payload: data });
    } else {
      yield put({ type: actions.GET_TEACHER_EARNINGS_FAILURE });
    }
  } catch (error) {
    yield put({ type: actions.GET_TEACHER_EARNINGS_FAILURE, payload: error });
  }
}

export function* getAllSimpleClassesSaga(payload) {
  try {
    const { data } = yield call(apis.getAllSimpleClasses, payload.payload);
    if (data) {
      yield put({ type: actions.GET_ALL_SIMPLE_CLASSES_SUCCESS, payload: data });
    } else {
      yield put({ type: actions.GET_ALL_SIMPLE_CLASSES_FAILURE });
    }
  } catch (error) {
    yield put({ type: actions.GET_ALL_SIMPLE_CLASSES_FAILURE, payload: error });
  }
}



export function* rootSaga() {
  yield all([
    takeEvery(actions.UPDATE_RULES, updateRulesSaga),
    takeEvery(actions.POST_ENDCLASS_REQUEST, endClassSaga),
    takeEvery(actions.POST_CREATECLASS_REQUEST, createClassSaga),
    takeEvery(actions.POST_LOGINDATA_REQUEST, postLoginDataSaga),
    takeEvery(actions.ENABLE_SPEAKALL_REQUEST, enableSpeakAllSaga),
    takeEvery(actions.CHANGE_STUDENT_RULE_REQUEST, changeStudentRulesSaga),
    takeEvery(actions.GET_ALL_OTS_CLASSES_REQUEST, getAllOtsClassesSaga),
    takeEvery(actions.GET_OTS_CLASS_REQUEST, getOtsClassSaga),
    takeEvery(actions.GET_ALL_CLASSES_REQUEST, getAllClassesSaga),
    takeEvery(actions.CREATE_OTS_REQUEST, createOTSClassSaga),
    takeEvery(actions.UPDATE_OTS_REQUEST, updateOTSClassSaga),
    takeEvery(actions.CREATE_OTSROOM_REQUEST, createOTSRoomSaga),
    takeEvery(actions.END_OTSROOM_REQUEST, endOTSRoomSaga),
    takeEvery(actions.FETCH_TEACHER_PROFILE_DETAILS_REQUEST, fetchTeacherProfileDetailsSaga),
    takeEvery(actions.UPDATE_TEACHER_PASSWORD_REQUEST, updateTeacherPasswordSaga),
    takeEvery(actions.TEACHER_PROFILE_BIO_UPDATE_REQUEST, teacherProfileBioUpdateSaga),
    takeEvery(actions.TEACHER_PROFILE_UPDATE_REQUEST, teacherProfileUpdateSaga),
    takeEvery(actions.TEACHER_PROFILE_IMAGE_UPDATE_REQUEST, teacherImageUpdateSaga),
    takeEvery(actions.GET_TEACHER_PERFORMANCE_REQUEST, teacherPerformanceSaga),
    takeEvery(actions.GET_TEACHER_EARNINGS_REQUEST, teacherEarningsSaga),
    takeEvery(actions.GET_ALL_SIMPLE_CLASSES_REQUEST, getAllSimpleClassesSaga),
  ]);
}
