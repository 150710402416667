import React, { useState } from 'react';
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import SideNav from "../SideNav/SideNav"
import Header from "../Header/header"
import { Layout, Menu } from 'antd';
import 'antd/dist/antd.css';

import Performance from '../../pages/Performance/index'
import Classes from '../../pages/Classes/index'
import Earnings from '../../pages/Earning/index'
import Home from '../../pages/HomeNew/index'
import OTSClasses from "../../pages/OTSClasses";
import BottomNav from '../BottomNav/bottomNav';
import CalendarView from '../../pages/Calendar/index';


const Content = Layout;

const Main = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const [index, setIndex] = useState(4)

  const handleIndex = (indexValue) => {
    setIndex(indexValue)
  }

  const handleCollapsable = (val) => {
    setCollapsed(val)
  }
  let ComponentRender = index
  switch (ComponentRender) {
    case 0:
      ComponentRender = <OTSClasses />;
      break;
    case 1:
      //ForCoaching
      ComponentRender = <OTSClasses />;
      break;
    case 2:
      ComponentRender = <Classes />;
      break;
    case 3:
      ComponentRender = <Earnings />;
      break;
    case 4:
      ComponentRender = <Performance />;
      break;
    case 5:
      //ComponentRender = <Classes />;
      //ForFeedback
      break;
    case 6:
      //ComponentRender = <Classes />;
      //ForResources
      break;
    case 7:
      ComponentRender = <CalendarView />;
      break;
    default:
      ComponentRender = <Performance />;
    // <Home />
  }

  return (
    <Layout style={{
      height: '100vh',
    }}>
      <SideNav collapsed={collapsed} indexCount={index} indexVal={handleIndex} collapsable={handleCollapsable} />
      <Layout className="site-layout">

        <Header className="site-layout-background" style={{ padding: 0 }} toggleCollapseState={() => {
          setCollapsed(!collapsed)
        }}>

        </Header>
        <Content
          className="site-layout-background"
          style={{
            minHeight: 280,
            backgroundColor: "white"
          }}
        >
          {ComponentRender}
        </Content>
      </Layout>
      <BottomNav indexCount={index} indexVal={handleIndex} />
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    isSideNavCollapse: state.isSideNavCollapse
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps)
)(Main);
