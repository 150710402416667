import React, { useState, useEffect, useRef, useStyles } from "react";
import { usePubNub } from "pubnub-react";
import { connect } from "react-redux";

import {
  addLocalParticipantId,
  changeStudentRules,
  communicationWay,
  muteUnmuteVideoPlayer,
  addId,
  removeId,
  zoomModal
} from "../../actions/actions";
import AboutIcon from "../../assets/img/icons/aboutIcon.svg";
import StudentInfoDialog from "./StudentInfoDialog";
import AudioLevelIndicator from "../AudioLevelIndicator";


const Participant = (props) => {
  const {
    participant,
    localParticipant,
    localParticipantId,
    localParticipantTracks,
    localParticipantSid,
    newParticipant,
    isTeacherMuted,
    otsRoomData,
    muteStudents,
    speakToAllEnabled,
    roomId,
    isZoomModal
  } = props;

  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [localAudioTrack, setLocalAudioTrack] = useState([]);
  const [isDoubleClicked, setDoubleClicked] = useState(false);
  const [open, setOpen] = useState(false);
  const channelName = `OTS_TWL_${otsRoomData.OTS.roomID}`;
  const [isClicked, setClicked] = useState(false);
  const pubnub = usePubNub();
  const styles = {
    border: isDoubleClicked
      ? "4px solid 	#62DF4C"
      : isClicked
        ? "4px solid 	#67CEF1"
        : null,
  };
  let clickCount = 0;
  let singleClickTimer = "";

  const videoRef = useRef();
  const audioRef = useRef();

  if (localParticipantSid) {
    props.addLocalParticipantId(localParticipantSid);
  }

  useEffect(() => {
    setClicked(false);
    setDoubleClicked(muteStudents);
  }, [muteStudents]);
  useEffect(() => {
    setClicked(speakToAllEnabled);
    if (muteStudents === false) setDoubleClicked(false);
  }, [speakToAllEnabled]);

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    const player = props.participantIds.length === 0 ? false : true;
    props.muteUnmuteVideoPlayer(player);
  }, [props.participantIds]);

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));
    if (localParticipantTracks) {
      setLocalAudioTrack(trackpubsToTracks(localParticipantTracks.audioTracks));
    }

    const trackSubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks, open, isZoomModal]);

  useEffect(() => {
    const audioTrack = audioTracks[0];

    if (audioTrack) {
      if (localParticipant && audioTrack.isEnabled === true) {
        audioTrack.disable();
      }
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks, localParticipant]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      if (localParticipant) {
        if (isTeacherMuted) {
          audioTrack.enable();
          props.muteUnmuteVideoPlayer(true);
        } else {
          audioTrack.disable();
          props.muteUnmuteVideoPlayer(false);
        }
      }
    }
  }, [isTeacherMuted]);

  const handleClick = () => {
    if (!muteStudents && !speakToAllEnabled) {
      clickCount++;
      if (clickCount === 1) {
        singleClickTimer = setTimeout(function () {
          clickCount = 0;
          singleClick();
        }, 300);
      } else if (clickCount === 2) {
        clearTimeout(singleClickTimer);
        clickCount = 0;
        doubleClick();
      }
    }
  };

  const allClicks = (code, stId, rules, player, way) => {
    pubnub.publish({
      channel: channelName,
      message: {
        statusCode: code,
        studentId: stId,
      },
    });
    props.changeStudentRules(rules);
    if (way) {
      props.communicationWay(way);
    }
    if (props.participantIds) {
      props.muteUnmuteVideoPlayer(player);
    }
  };

  const singleClick = () => {
    const audioTrack = localAudioTrack[0];
    const id = participant.sid;
    if (isClicked) {
      if (!muteStudents) {
        audioTrack.disable();
      }
      props.removeId(id);
      const rules = {
        type: "exclude",
        roomId: roomId,
        studentId: participant.sid,
        teacherId: localParticipantId,
      };
      allClicks(100, id, rules, false);
    } else {
      const rules = {
        type: "include",
        roomId: roomId,
        studentId: participant.sid,
        teacherId: localParticipantId,
      };
      audioTrack && audioTrack.enable();
      allClicks(101, id, rules, true, false);
      setDoubleClicked(false);
      props.addId(id);
    }
    setClicked(!isClicked);
  };

  const doubleClick = () => {
    const audioTrack = localAudioTrack[0];
    const id = participant.sid;
    if (!localParticipant) {
      if (isDoubleClicked) {
        if (!muteStudents) {
          audioTrack.disable();
        }
        props.removeId(id);
        const rules = {
          type: "exclude",
          roomId: roomId,
          studentId: participant.sid,
          teacherId: localParticipantId,
        };
        allClicks(104, id, rules, false, false);
      } else {
        const rules = {
          type: "include",
          roomId: roomId,
          studentId: participant.sid,
          teacherId: localParticipantId,
        };
        props.addId(id);
        audioTrack && audioTrack.enable();
        allClicks(103, id, rules, true, true);
        setClicked(false);
      }
      setDoubleClicked(!isDoubleClicked);
    }
  };

  const participantStyle = {
    position: "relative",
    width:
      newParticipant > 4 && newParticipant < 11
        ? 220
        : newParticipant > 10
          ? 200
          : null,
    height:
      newParticipant > 4 && newParticipant < 11
        ? 124
        : newParticipant > 10
          ? 113
          : null,
    marginLeft: newParticipant > 4 ? 7 : 0,
    marginBottom: newParticipant > 4 ? 0 : 0,
    bottom: newParticipant > 4 ? 30 : null,
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const closeDetailModal = () => {
    setOpen(false);
  };

  return (
    <>
      {!localParticipant ? (
        <>
          <div className="participant"
            style={{ marginBottom: window.innerWidth < 767 && newParticipant === props.index + 1 ? 80 : null }}
          >
            <div
              className="participant"
              style={participantStyle}
              onClick={handleClick}
            >
              <video ref={videoRef} autoPlay={true} style={styles} />
              <audio
                ref={audioRef}
                autoPlay={true} muted={false} />
            </div>
            <div
              className="identity"
            >
              {participant.identity}
            </div>
            <div
              className="mic-animation"
            >
              {isDoubleClicked && <AudioLevelIndicator audioTrack={audioTracks.length > 0 ? audioTracks[0] : null} />}
            </div>
            <div
              className="identityy"
              onClick={handleClickOpen}
            >
              <img alt="" src={AboutIcon} />
            </div>
          </div>
          {open && (
            <StudentInfoDialog
              closeDetailModal={closeDetailModal}
              doubleClick={doubleClick}
              isDoubleClicked={isDoubleClicked}
              identity={participant.identity}
              videoTracks={videoTracks}
              audioRef={audioRef}
              videoRef={videoRef}
            />
          )}
        </>
      ) : null}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    communicationWay: (data) => dispatch(communicationWay(data)),
    changeStudentRules: (data) => dispatch(changeStudentRules(data)),
    addLocalParticipantId: (data) => dispatch(addLocalParticipantId(data)),
    muteUnmuteVideoPlayer: (data) => dispatch(muteUnmuteVideoPlayer(data)),
    addId: (data) => dispatch(addId(data)),
    removeId: (data) => dispatch(removeId(data)),
    zoomModal: (data) => dispatch(zoomModal(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    studentIds: state.studentIds,
    otsRoomData: state.otsRoomData,
    muteStudents: state.muteStudents,
    isTeacherMuted: state.isTeacherMuted,
    newParticipant: state.newParticipant,
    speakToAllEnabled: state.speakToAllEnabled,
    participantIds: state.participantIds,
    isZoomModal: state.isZoomModal,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Participant);
