import React from 'react';
import { Button } from 'antd';

const Title = (props) => {

  const { text, index } = props;

  return (
    <div className="page-inner-title-main">
      <div className='page-inner-title'>
        <span>{text}</span>
      </div>
      {
        index === 3 ?
          <Button className='classes-button'>
            <span className='classes-button-span'>
              Add New
            </span>
          </Button> :
          null
      }
    </div>
  );
}

export default Title;
