import React from "react";
import moment from 'moment'
import { Row, Col } from "antd";

import StatusBar from '../SimpleClasses/statusBar';
import ThumbnailImage from '../../assets/img/no-thumbnail-image.jpeg';
import { ReactComponent as Dollar } from '../../assets/img/icons/dollar.svg';
import { ReactComponent as Visibility } from '../../assets/img/icons/visibility-on.svg';


const SimpleClasses = (props) => {

    const { data, status } = props;

    return (
        <div style={{
            padding: 4,
        }}>
            <Row className="simple-classes-card-row" style={{ height: '100%', marginTop: 8 }}>
                <Col span={24} style={{ backgroundColor: '#fff', borderRadius: 8 }}>
                    <div className="classes-card-main" >
                        <div className="classes-card-image">
                            <img alt="card-image"
                                src={data.class.thumbNailURL ?? ThumbnailImage}
                            />
                        </div>
                        <div className='simple-classes-card'>

                            <div className="simple-classes-card-upper">
                                <div className='simple-classes-card-content' style={{ display: 'flex' }}>
                                    <span className='simple-classes-card-content-title'>
                                        {data.class.name}
                                    </span>
                                    <span className='simple-classes-card-content-description'>
                                        {data.class.category === " " || data.class.category === "" ? `${parseInt((data.class.videoDuration ?? 0) / 60)} min` : `${data.class.category} · ${parseInt((data.class.videoDuration ?? 0) / 60)} min`}
                                    </span>
                                </div>
                            </div>

                            <div className="simple-classes-card-lower" style={{ display: 'flex' }}>
                                {status ?
                                    <>
                                        <div className="simple-classes-card-content-views">
                                            <span><Visibility style={{ marginTop: 3 }} /></span>
                                            <span style={{ marginLeft: 8, marginTop: -4 }} >
                                                {data.paidViews}
                                            </span>
                                        </div>
                                        <div className="simple-classes-card-content-amount">
                                            <span>
                                                <Dollar style={{ marginTop: 3 }} />
                                            </span>
                                            <span style={{ marginLeft: 8, marginTop: -4 }} >
                                                {data.earnings.toFixed(2)}
                                            </span>
                                        </div>
                                    </>
                                    :
                                    null
                                }
                                <div className="classes-status-bar">
                                    <StatusBar text={status === 'expired' ? `Expired ${moment(data.class.startDateTime).format('DD MMM')}` : status === 'published' && window.innerWidth < 420 ? `${moment(data.class.startDateTime).format('DD MMM')}` : `Published ${moment(data.class.startDateTime).format('DD MMM')}`} status={status} />
                                </div>

                            </div>
                        </div>
                    </div>

                </Col>
            </Row>
        </div>
    );
}

export default SimpleClasses;